























































































































































































































































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import moment from "moment-jalaali";
import GeneralApi from "@/networks/GeneralApi";
import { AxiosResponse } from "axios";
import store from "@/store/index";
@Component
export default class component_name extends Vue {
  @Prop()
  transaction!: any;
  image = "";
  dialog = false;
  FormatDate(date: any) {
    return moment(date).format("hh:mm | jYYYY jMMMM jDD");
  }
  public async download(item: Record<string, string>): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().download(item.id, {
        public: "true"
      });
      if (item.mime.substr(0, 5) == "image") {
        this.dialog = true;
        this.image = res.data.data;
      } else this.forceFileDownload(res, item.original_name);
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }
  forceFileDownload(response: AxiosResponse, title: string) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", title);
    document.body.appendChild(link);
    link.click();
  }
  CounselingStatus(status: any) {
    if (status == 0) {
      return "وضعیت اولیه";
    } else if (status == 1) {
      return "ارسال به درگاه";
    } else if (status == 2) {
      return "موفق";
    } else {
      return "نا موفق";
    }
  }
  CounselingStatusColor(status: any) {
    if (status == 4) {
      return "error";
    } else if (status == 2) {
      return "green";
    }
  }
}
