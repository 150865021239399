

















































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import store from "../../../store";
import { AxiosError, AxiosResponse } from "axios";
import { EventApi } from "@/networks/EventApi";
import { UserApi } from "@/networks/UserApi";
import Enum from "@/config/enum";
import GeneralApi from "@/networks/GeneralApi";
import moment from "moment-jalaali";
import Transactions from "@/views/pages/Profile/File/Detail/Transactions.vue";
import FieldUser from "@/views/components/Common/Filed.vue";
@Component({
  components: { Transactions, FieldUser }
})
export default class General extends Vue {
  private event: Record<string, any> = {
    user: {
      avatar: "",
      fname: "",
      lname: ""
    }
  };
  private staff = [];
  private payloading = false;
  private dialog = false;
  private image = "";
  private roles = localStorage.getItem("roles") || [];
  private eventStatus = Enum.eventStatusAdmin;
  private paymentType = Enum.TypePayment;
  private baseUrl = process.env.VUE_APP_URL || "https://api.bms-go2tr.com";

  mounted() {
    this.getStaff();
    this.View();
  }

  public async View(): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().view(
        Number(this.$route.params.id)
      );
      this.event = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  staffClass(staff: string): string {
    let color;
    switch (staff) {
      case "1":
        color = "green";
        break;
      case "2":
        color = "red";
        break;
      case "3":
        color = "blue";
        break;
      case "4":
        color = "purple";
        break;
      default:
        color = "gray";
    }
    return color;
  }
  FormatDate(date: string) {
    return moment(date).format("hh:mm | jYYYY jMMMM jDD");
  }
  CounselingStatus(status: number) {
    if (status == 0) {
      return "وضعیت اولیه";
    } else if (status == 1) {
      return "ارسال به درگاه";
    } else if (status == 2) {
      return "موفق";
    } else {
      return "نا موفق";
    }
  }
  CounselingStatusColor(status: any) {
    if (status == 4) {
      return "error";
    } else if (status == 2) {
      return "green";
    }
  }
  public async getStaff(): Promise<void> {
    try {
      const res: AxiosResponse = await new UserApi().getStaff();
      this.staff = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  geteventStatus(status: any) {
    const item: any = this.eventStatus.filter((x) => x.value == status);
    return item ? item.text : "";
  }

  public async pay(id: string): Promise<void> {
    this.payloading = true;
    try {
      const res: AxiosResponse = await new EventApi().pay(id);
      window.location.href = res.data.data.url.action;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    } finally {
      this.payloading = false;
    }
  }

  public async download(item: Record<string, string>): Promise<void> {
    this.payloading = true;
    try {
      const res: AxiosResponse = await new GeneralApi().download(item.id, {
        public: "true"
      });
      if (item.mime.substr(0, 5) == "image") {
        this.image = res.data.data;
        this.dialog = true;
      } else this.forceFileDownload(res, item.original_name);
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    } finally {
      this.payloading = false;
    }
  }

  getPaymentType(text: any) {
    let item = this.paymentType.find((x) => x.value == text);
    return item ? item.text : "";
  }

  forceFileDownload(response: AxiosResponse, title: string) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", title);
    document.body.appendChild(link);
    link.click();
  }

  getStaffName(id: number): string {
    const x: any = this.staff.find((x: any) => x.id == id);
    if (x) {
      return x.name;
    } else {
      return "بدون نام";
    }
  }

  public getStaffItem(id: number): string {
    const x: any = this.staff.filter((x: any) => x.id == id);
    let icon = "mdi-star";
    switch (x.length > 0 ? x[0].name : "") {
      case "مشاور حضوری اول":
        icon = "mdi-alpha-i";
        break;
      case "مشاور حضوری دوم":
        icon = "mdi-roman-numeral-2";
        break;
      case "اپراتور":
        icon = "mdi-phone";
        break;
      case "مدیر سالن":
        icon = "mdi-account";
        break;
      case "DCM":
        icon = "mdi-1";
        break;
    }
    return icon;
  }
}
