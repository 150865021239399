











import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import { UserApi } from "@/networks/UserApi";
@Component
export default class component_name extends Vue {
  value = null;
  @Prop()
  user_id!: any;
  @Prop()
  field!: string;
  get link() {
    if (this.field == "mobile") {
      return `tel://${this.value}`;
    } else {
      return `mailto:${this.value}`;
    }
  }
  async showValue() {
    try {
      const { data } = await new UserApi().getUserSensitiveInfo(
        this.user_id,
        this.field
      );
      this.value = data.data;
    } catch (err) {
      console.log(err);
    }
  }
}
